.hero-section {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  align-items: center;
  

}

.overlay{
background: rgb(0,129,204);
background: -moz-linear-gradient(90deg, rgba(0,129,204,1) 6%, rgba(0,163,204,1) 36%, rgba(0,204,190,1) 52%, rgba(72,218,208,0.9094231442577031) 55%, rgba(108,225,217,0.5256696428571428) 57%, rgba(162,235,230,0.4584427521008403) 59%, rgba(198,242,239,0.29037552521008403) 61%, rgba(252,252,252,0) 64%);
background: -webkit-linear-gradient(90deg, rgba(0,129,204,1) 6%, rgba(0,163,204,1) 36%, rgba(0,204,190,1) 52%, rgba(72,218,208,0.9094231442577031) 55%, rgba(108,225,217,0.5256696428571428) 57%, rgba(162,235,230,0.4584427521008403) 59%, rgba(198,242,239,0.29037552521008403) 61%, rgba(252,252,252,0) 64%);
background: linear-gradient(90deg, rgba(0,129,204,1) 6%, rgba(0,163,204,1) 36%, rgba(0,204,190,1) 52%, rgba(72,218,208,0.9094231442577031) 55%, rgba(108,225,217,0.5256696428571428) 57%, rgba(162,235,230,0.4584427521008403) 59%, rgba(198,242,239,0.29037552521008403) 61%, rgba(252,252,252,0) 64%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0081cc",endColorstr="#fcfcfc",GradientType=1);

width: 100%;
height: 100%;
position: absolute;
}

.custom-linkedin-icon {
  font-size: 4rem; /* Adjust the size as needed */
  color: #242424;
  padding-left: 40px;
  
}

.social-icons{
  display: flex;
  flex-direction:row;
  margin: auto auto;
  align-items:center;
  justify-content: center;
}

.custom-linkedin-icon:hover{
  font-size: 4rem; /* Adjust the size as needed */
  color: #fff;
  padding-left: 40px;
  transition: 0.3s ease-out;
}


.hero-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left:20px;
}

.hero-text {
  flex: 1;
  color: #fff;
  z-index: 2;
}

h2 {
  font-size: 5rem;
  margin-bottom: 20px;
  font-family: "opensans-bold";
}

 p {
  font-size: 1.8rem;
  font-family: "librebaskerville-regular";
}

.hero-image {
  flex: 1;
  text-align: right;
  position: relative; /* Add this to set the aspect ratio */
  z-index: -1;
}

img {
  max-width: 100%;
  height: 100vh; /* Set height to 100% to maintain aspect ratio */
  object-fit: cover; /* Maintain aspect ratio and cover the container */
  width: auto; /* Allow width to adjust automatically */
}

.custom-texus-icon {
  width: 4rem; /* Adjust as needed */
  height: 4rem; /* Adjust as needed */
  padding-left:40px;
}

.custom-texus-icon:hover {
  transform: scale(1.2);
  transition:all 0.3s;
}
@media (max-width: 768px) {
  .hero-section{
    height: auto;
  }

  .overlay{
    display: none;
  }
  .hero-content {
    flex-direction: column;
    text-align: center;
    margin: auto auto;
    padding:10px;
  }

  .hero-image {
    order: -1; /* Move the image to the top for mobile version */
    object-fit:cover;
    width:100%;
    position:relative;

  }
  .hero-image img{
    height: 30vh;
    width: 100%;
    transform: translate(0, 0);
  }

  h2 {
    font-size: 3.5rem;
    margin-bottom: 10px;
    color: black;
  }

  .hero-text p {
    font-size: 1rem;
    color: black;
  }

  .custom-linkedin-icon {
    padding-left: 30px;
  }
  .custom-texus-icon {
  padding-left:30px;
}
}