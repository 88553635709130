nav {
  background-color: #333;
  padding: 10px 0;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  z-index: 999;
  position: sticky;
  top: 0;
}

nav.hidden {
  transform: translateY(-100%);
  opacity: 0;
  transition: transform 0.3s ease-in-out;
  pointer-events: none;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

li {
  margin: 0 20px;
}

/* Apply the same styles to both button and button-link elements */
button, .button-link {
  background: none;
  border: none;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  padding: 10px 20px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  text-decoration: none;
}

/* Apply the same hover styles to both button and button-link elements */
button:hover, .button-link:hover {
  color: #fff;
  background-color: #ff8c38;
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

li:hover {
  transform: translateY(-2px);
  transition: transform 0.2s ease-in-out;
}

/* Additional styles from your previous input */

.mobile-menu-toggle {
  display: block;
  cursor: pointer;
  font-size: 24px;
  color: #fff;
  padding: 10px;
  position: absolute;
  top: 10px; /* Adjust as needed */
  right: 10px; /* Adjust as needed */
  z-index: 1000; /* Ensure it's above the menu */
  background: none;
  border: none;
  outline: none;
}

.mobile-menu-toggle:hover {
  color: #ff8c38;
}

.nav-list.mobile-menu-open {
  display: grid;
  grid-template-columns: 1fr; /* Single column for buttons */
  grid-template-rows: repeat(auto-fill, minmax(60px, 1fr));
  grid-gap: 10px; /* Adjust spacing as needed */
  background-color: #333;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateY(-100%);
  transition: transform 0.3s ease-in-out;
  z-index: 999;
  padding: 20px; /* Adjust padding as needed */
}

.nav-list.active {
  transform: translateY(0);
}

.nav-list li {
  text-align: center;
}

.nav-list a {
  color: #fff;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.3s ease-in-out;
}

.nav-list a:hover {
  color: #ff8c38;
}
