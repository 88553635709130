.bio {
  display: flex;
  justify-content: space-around; /* Change this to adjust the space between sections */
}

.section {
  display: flex;
  flex-direction: column;
  width: 48%; /* Adjust the width of each section as needed */
  margin-bottom: 0px; /* Adjust the space between sections */
  margin: 20px;
  
}

.title {
  background-color: #fff; /* Background color for titles */
  padding: 10px;
  color: black;
}

.title h2 {
  font-size: 2rem;
  font-family: "opensans-bold";
  text-transform: uppercase;
  text-decoration: underline #0085cc; /* Change this to the style you want */
  text-underline-offset: 3px;
  color: black;
}


.work,
.honors,
.publications {
  display: flex;
  flex-direction: column;
  text-align: left;
  font-family: "librebaskerville-italic";
  margin: 20px;
  color: black;
}

.publications {
  color: grey;
}

.publication-item p {
  font-size: 1rem;
  color: black;
}


.work-item,
.honor-item,
.publication-item {
  margin-bottom: 0px; /* Spacing between education/work/honors/publications items */
  padding: 0px;
  font-size: 1rem;
  color: black;
   border-right: 1 px solid grey;

}




.work-item p {
  font-size: 0.8rem;
  color: black;
}

.honor-item p,
.publication-item p {
  font-size: 0.8rem;
  color: black;
}

.work-item:not(:last-child),
.honor-item:not(:last-child),
.publication-item:not(:last-child) {
  border-bottom: 1px solid grey;
  
}


@media (max-width: 768px) {
  .bio {
    flex-direction: column; /* Change to a column layout for smaller screens */
    align-items: center;
  }

  .section {
    width: 100%; /* Make sections occupy full width on smaller screens */
  }

  .title h2 {
    text-decoration: underline; /* Remove color from text-decoration for better visibility */
  }


  .work,
  .honors,
  .publications {
    margin: 10px; /* Adjust margin for better spacing on smaller screens */
  }

  /* Add any other specific styles needed for mobile view */
}
